var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-table',{staticStyle:{"width":"1200px","margin":"0 auto","min-height":"500px","border-radius":"4px"},attrs:{"data":_vm.tableData}},[_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"companyContainer"},[_c('img',{staticClass:"companyLogo",attrs:{"src":scope.row.logo,"alt":""}}),_c('div',{staticStyle:{"display":"inline-block"}},[_c('div',{staticClass:"companyName",attrs:{"title":scope.row.name}},[_vm._v(" "+_vm._s(scope.row.name)+" ")]),_c('div',{staticClass:"companyDes"},[_vm._v(" "+_vm._s(scope.row.address)+" ")])])])]}}])},[_c('template',{slot:"header"},[_c('div',[_vm._v("公司名称")]),_c('div',[_vm._v("公司地点")])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
            scope.row.careerFair &&
            scope.row.careerFair.details['1'] &&
            scope.row.careerFair.details['1'].annualSalary
          )?_c('div',{staticClass:"orange"},[_vm._v(" "+_vm._s(!scope.row.isDomestic ? "$" : "￥")+_vm._s(scope.row.careerFair.details["1"].annualSalary)+" /y ")]):_c('div',{staticClass:"orange",staticStyle:{"cursor":"pointer","text-decoration":"underline","display":"inline-block"},on:{"click":function($event){return _vm.provideResource(scope.row)}}},[_vm._v(" 提供信息 ")]),_c('div',{staticClass:"newGrad",staticStyle:{"cursor":"pointer"}},[(
              scope.row.careerFair &&
              scope.row.careerFair.details['1'] &&
              scope.row.careerFair.details['1'].link
            )?_c('div',{staticClass:"applyLink",on:{"click":function($event){return _vm.jumpLink(scope.row.careerFair.details['1'].link)}}},[_vm._v(" Apply → ")]):_c('div',{staticClass:"notOpenLink",on:{"click":function($event){return _vm.jumpLink(scope.row.careerFair.link)}}},[_vm._v(" Unknown ")])])]}}])},[_c('template',{slot:"header"},[_c('div',[_c('div',{staticClass:"orange"},[_vm._v("New Grad Salary /y")]),_c('div',{staticClass:"orange"},[_vm._v("New Grad Link")])])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
            scope.row.careerFair &&
            scope.row.careerFair.details['0'] &&
            scope.row.careerFair.details['0'].monthlySalary
          )?_c('div',{staticClass:"blue"},[_vm._v(" "+_vm._s(!scope.row.isDomestic ? "$" : "￥")+_vm._s(scope.row.careerFair.details["0"].monthlySalary)+" /m ")]):_c('div',{staticClass:"blue",staticStyle:{"cursor":"pointer","text-decoration":"underline","display":"inline-block"},on:{"click":function($event){return _vm.provideResource(scope.row)}}},[_vm._v(" 提供信息 ")]),_c('div',{staticClass:"internship"},[(
              scope.row.careerFair &&
              scope.row.careerFair.details['0'] &&
              scope.row.careerFair.details['0'].link
            )?_c('div',{staticClass:"applyLink",on:{"click":function($event){return _vm.jumpLink(scope.row.careerFair.details['0'].link)}}},[_vm._v(" Apply → ")]):_c('div',{staticClass:"notOpenLink",on:{"click":function($event){return _vm.jumpLink(scope.row.careerFair.link)}}},[_vm._v(" Unknown ")])])]}}])},[_c('template',{slot:"header"},[_c('div',[_c('div',{staticClass:"blue"},[_vm._v("Internship Salary /m")]),_c('div',{staticClass:"blue"},[_vm._v("Internship Link")])])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
            scope.row.careerFair &&
            scope.row.careerFair.details['2'] &&
            scope.row.careerFair.details['2'].link
          )?_c('div',{staticClass:"applyLink",on:{"click":function($event){return _vm.jumpLink(scope.row.careerFair.details['2'].link)}}},[_vm._v(" Apply → ")]):_c('div',{staticClass:"notOpenLink",on:{"click":function($event){return _vm.jumpLink(scope.row.careerFair.link)}}},[_vm._v(" Unknown ")])]}}])},[_c('template',{slot:"header"},[_c('div',[_c('div',{staticClass:"green"},[_vm._v("在职跳槽Link")])])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"applyLink",staticStyle:{"margin-bottom":"6px"},on:{"click":function($event){return _vm.accept(scope.row)}}},[_vm._v(" 接受 ")]),_c('br'),_c('div',{staticClass:"notOpenLink",on:{"click":function($event){return _vm.refuse(scope.row)}}},[_vm._v("拒绝")])]}}])},[_c('template',{slot:"header"},[_vm._v("操作")])],2)],1),_c('div',{staticStyle:{"text-align":"center","padding":"20px"}},[(_vm.total > 10)?_c('el-pagination',{attrs:{"current-page":_vm.currentPage,"total":_vm.total,"layout":"prev, pager, next"},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"current-change":_vm.getAccessList}}):_vm._e()],1),_c('CvDialog',{attrs:{"visible":_vm.cvDialogVisible,"type":"acceptInvite","inviteId":_vm.currentInviteId},on:{"changeDialogVisible":(val) => (_vm.cvDialogVisible = val),"reloadList":function($event){return _vm.getReferInviteList(_vm.currentPage)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }