<template>
  <!-- 内推邀请 -->
  <div>
    <el-table
      :data="tableData"
      style="
        width: 1200px;
        margin: 0 auto;
        min-height: 500px;
        border-radius: 4px;
      "
    >
      <el-table-column>
        <template slot="header">
          <div>公司名称</div>
          <div>公司地点</div>
        </template>
        <template slot-scope="scope">
          <div class="companyContainer">
            <img class="companyLogo" :src="scope.row.logo" alt="" />
            <div style="display: inline-block">
              <div class="companyName" :title="scope.row.name">
                {{ scope.row.name }}
              </div>
              <div class="companyDes">
                {{ scope.row.address }}
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div>
            <div class="orange">New Grad Salary /y</div>
            <div class="orange">New Grad Link</div>
          </div>
        </template>
        <template slot-scope="scope">
          <div
            class="orange"
            v-if="
              scope.row.careerFair &&
              scope.row.careerFair.details['1'] &&
              scope.row.careerFair.details['1'].annualSalary
            "
          >
            {{ !scope.row.isDomestic ? "$" : "￥"
            }}{{ scope.row.careerFair.details["1"].annualSalary }} /y
          </div>
          <div
            class="orange"
            v-else
            style="
              cursor: pointer;
              text-decoration: underline;
              display: inline-block;
            "
            @click="provideResource(scope.row)"
          >
            提供信息
          </div>
          <div class="newGrad" style="cursor: pointer">
            <div
              class="applyLink"
              v-if="
                scope.row.careerFair &&
                scope.row.careerFair.details['1'] &&
                scope.row.careerFair.details['1'].link
              "
              @click="jumpLink(scope.row.careerFair.details['1'].link)"
            >
              Apply&nbsp;→
            </div>
            <div
              class="notOpenLink"
              v-else
              @click="jumpLink(scope.row.careerFair.link)"
            >
              Unknown
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div>
            <div class="blue">Internship Salary /m</div>
            <div class="blue">Internship Link</div>
          </div>
        </template>
        <template slot-scope="scope">
          <div
            class="blue"
            v-if="
              scope.row.careerFair &&
              scope.row.careerFair.details['0'] &&
              scope.row.careerFair.details['0'].monthlySalary
            "
          >
            {{ !scope.row.isDomestic ? "$" : "￥"
            }}{{ scope.row.careerFair.details["0"].monthlySalary }} /m
          </div>
          <div
            class="blue"
            v-else
            style="
              cursor: pointer;
              text-decoration: underline;
              display: inline-block;
            "
            @click="provideResource(scope.row)"
          >
            提供信息
          </div>
          <div class="internship">
            <div
              class="applyLink"
              v-if="
                scope.row.careerFair &&
                scope.row.careerFair.details['0'] &&
                scope.row.careerFair.details['0'].link
              "
              @click="jumpLink(scope.row.careerFair.details['0'].link)"
            >
              Apply&nbsp;→
            </div>
            <div
              class="notOpenLink"
              v-else
              @click="jumpLink(scope.row.careerFair.link)"
            >
              Unknown
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div>
            <div class="green">在职跳槽Link</div>
          </div>
        </template>
        <template slot-scope="scope">
          <div
            class="applyLink"
            v-if="
              scope.row.careerFair &&
              scope.row.careerFair.details['2'] &&
              scope.row.careerFair.details['2'].link
            "
            @click="jumpLink(scope.row.careerFair.details['2'].link)"
          >
            Apply&nbsp;→
          </div>
          <div
            class="notOpenLink"
            v-else
            @click="jumpLink(scope.row.careerFair.link)"
          >
            Unknown
          </div>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">操作</template>
        <template slot-scope="scope">
          <div
            class="applyLink"
            @click="accept(scope.row)"
            style="margin-bottom: 6px"
          >
            接受
          </div>
          <br />
          <div class="notOpenLink" @click="refuse(scope.row)">拒绝</div>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center; padding: 20px">
      <el-pagination
        v-if="total > 10"
        :current-page.sync="currentPage"
        :total="total"
        layout="prev, pager, next"
        @current-change="getAccessList"
      >
      </el-pagination>
    </div>
    <CvDialog
      :visible="cvDialogVisible"
      @changeDialogVisible="(val) => (cvDialogVisible = val)"
      type="acceptInvite"
      :inviteId="currentInviteId"
      @reloadList="getReferInviteList(currentPage)"
    ></CvDialog>
  </div>
</template>
<script>
import CvDialog from "../../interpolation/cvDialog";
import { getReferInviteList, refuseInvite } from "@/service/userCenter";
export default {
  name: "ReferInvite",
  components: { CvDialog },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      total: 0,
      form: {},
      cvDialogVisible: false,
      currentInviteId: "",
    };
  },
  methods: {
    getReferInviteList(page) {
      this.currentPage = page ? page : 1;
      getReferInviteList({ current: this.currentPage, ...this.form }).then(
        (res) => {
          if (res.success) {
            this.tableData = res.result.records;
            this.total = res.result.total;
          }
        }
      );
    },
    provideResource(item) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
      }
      this.$router.push(
        `/provideResource?type=editResource&companyId=${item.id}&companyName=${item.name}`
      );
    },
    jumpLink(url) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
      }
      window.open(url);
    },
    accept(item) {
      this.cvDialogVisible = true;
      this.currentInviteId = item.id;
    },
    refuse(item) {
      this.$confirm("确认拒绝此邀约？").then(() => {
        refuseInvite(item.id).then((res) => {
          if (res.success) {
            this.$message.success("拒绝成功！");
            this.getReferInviteList(this.currentPage);
          }
        });
      });
    },
  },
  mounted() {
    this.getReferInviteList();
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-table th {
  background: #f4f4f4;
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #34495e;
  line-height: 14px;
  padding-left: 12px;
}
::v-deep .el-table td {
  padding: 20px 12px;
}
.companyLogo {
  width: 30px;
  height: 30px;
  margin-right: 12px;
  vertical-align: top;
  transform: translateY(6px);
}
.companyName {
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #34495e;
  line-height: 21px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: top;
}
.companyDes {
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: rgba(52, 73, 94, 0.8);
  line-height: 18px;
}
.applyLink {
  padding: 4px 8px;
  display: inline-block;
  background: rgba(10, 122, 255, 0.3);
  border-radius: 12px;
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #0a7aff;
  line-height: 18px;
  cursor: pointer;
  &.access {
    padding: 4px 20px;
  }
}
.notOpenLink {
  padding: 4px 8px;
  display: inline-block;
  background: rgba(118, 118, 128, 0.24);
  border-radius: 12px;
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #757575;
  line-height: 18px;
  cursor: pointer;
}
.orange {
  color: rgba(250, 100, 0, 1);
  font-size: 14px;
}
.blue {
  color: rgba(10, 122, 255, 1);
  font-size: 14px;
}
.green {
  color: rgba(36, 199, 108, 1);
  font-size: 14px;
}
</style>